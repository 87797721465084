import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PiaService } from 'app/entry/pia.service';
import { ModalsService } from 'app/modals/modals.service';
import { AttachmentsService } from 'app/entry/attachments/attachments.service';
import { ActionPlanService } from 'app/entry/entry-content/action-plan//action-plan.service';
import { AppDataService } from '../../../services/app-data.service';
import { PermissionsService } from '@security/permissions.service';

@Component({
  selector: 'app-validate-pia',
  templateUrl: './validate-pia.component.html',
  styleUrls: ['./validate-pia.component.scss'],
  providers: []
})
export class ValidatePIAComponent implements OnInit {

  attachment: any;
  data: { sections: any };
  hasValidationPermission: boolean = false;
  validateForm: FormGroup;
  validatePiaAttachmentForm: FormGroup;

  constructor(
    private el: ElementRef,
    private _modalsService: ModalsService,
    public _attachmentsService: AttachmentsService,
    private _actionPlanService: ActionPlanService,
    public _piaService: PiaService,
    private _appDataService: AppDataService,
    private permissionsService: PermissionsService,
  ) {
      this.permissionsService.hasPermission('CanValidatePIA').then((hasPerm: boolean) => {
        if (hasPerm) {
          this.hasValidationPermission = true;
        }
      });
    }

  ngOnInit() {
    if (this._appDataService.allUsers) {
        this._appDataService.allUsers.forEach((user) => {
            if (user.id === this._piaService.pia.processing.supervisors.data_controller_id) {
                this._piaService.pia.validator_name = `${user.firstName} ${user.lastName}`
            }
        });
    }
    this.validatePiaAttachmentForm = new FormGroup({
        attachment_file: new FormControl('', [])
    });
    this.validateForm = new FormGroup({
        validateStatus1: new FormControl(),
        validateStatus2: new FormControl(),
        validateStatus3: new FormControl(),
        validateStatus4: new FormControl()
    });
    this.validateForm.controls['validateStatus1'].patchValue(this._piaService.pia.status > 1);
    this.validateForm.controls['validateStatus2'].patchValue(this._piaService.pia.status > 1);
    this.validateForm.controls['validateStatus3'].patchValue(this._piaService.pia.status > 1);
    this.validateForm.controls['validateStatus4'].patchValue(this._piaService.pia.status > 1);
    // initialize pia
    this._attachmentsService.pia = this._piaService.pia;
    this._attachmentsService.updateSignedAttachmentsList();
    this._actionPlanService.listActionPlan();
  }

  /**
   * Open the dialog box to select an attachment to upload
   * @memberof ValidatePIAComponent
   */
  addAttachment() {
    this._attachmentsService.pia_signed = 1;
    const attachment: any = document.querySelector('[formcontrolname="attachment_file"]');
    attachment.click();
  }

  /**
   * Allows users to upload an attachment for a specific Processing.
   * @param {event} event - Any kind of event.
   * @memberof ValidatePIAComponent
   */
  uploadAttachement(event: Event) {
    this._attachmentsService.upload((<HTMLInputElement>event.target).files[0]);
  }

  /**
   * Download an attachment
   * @param {number} id - Attachment id.
   * @memberof ValidatePIAComponent
   */
  downloadAttachment(id: number) {
    this._attachmentsService.downloadAttachment(id);
  }

  /**
   * Destroy an attachment
   * @param {number} id - Attachment id.
   * @memberof ValidatePIAComponent
   */
  removeAttachment(id: number) {
    localStorage.setItem('attachment-id', id.toString());
    this._modalsService.openModal('modal-remove-attachment');
  }

  /**
   * Locks radio buttons after click.
   * @param {any} event - Any Event.
   * @memberof ValidatePIAComponent
   */
  lockStatus(event: any) {
    if (this._piaService.pia.status > 1 || this._piaService.pia.is_example) {
      return false;
    } else {
      const clickedRadioButton = event.target || event.srcElement || event.currentTarget;
      //clickedRadioButton.setAttribute('disabled', true); // this aims to be a backstop, avoiding going any back after a validation
      this.checkValidationFormStatus();
    }
  }

  /**
   * Allows users to make a simple validation of a PIA.
   * @memberof ValidatePIAComponent
   */
  PIARejection() {
    this._piaService.pia.status = 1;
    this._piaService.saveCurrentPia().subscribe();
  }

  /**
   * Allows users to make a simple validation of a PIA.
   * @memberof ValidatePIAComponent
   */
  simplePIAValidation() {
    this._piaService.pia.status = 2;
    this._piaService.saveCurrentPia().subscribe(() => {
      this._modalsService.openModal('modal-simple-pia-validation');
    });
  }

  /**
   * Allows users to make a signed validation of a PIA.
   * @memberof ValidatePIAComponent
   */
  signedPIAValidation() {
    this._piaService.pia.status = 3;
    this._piaService.saveCurrentPia().subscribe(() => {
      this._modalsService.openModal('modal-signed-pia-validation');
    });
  }

  /**
   * Checks if the form is valid (radio buttons all checked).
   * If so, enables validation buttons.
   * @memberof ValidatePIAComponent
   */
  private checkValidationFormStatus() {
    let allBtnChecked = true;
    const radioButtons = document.querySelectorAll('.pia-entryContentBlock-content-list-confirm input');
    const simpleValidationBtn = document.getElementById('pia-simple-validation');
    const signValidationBtn = document.getElementById('pia-sign-validation');

    [].forEach.call(radioButtons, function (currentRadioBtn) {
      if (!currentRadioBtn.checked) {
        allBtnChecked = false;
      }
    });
    if (allBtnChecked && this.hasValidationPermission) {
      simpleValidationBtn.removeAttribute('disabled');
      signValidationBtn.removeAttribute('disabled');
    } else {
      simpleValidationBtn.setAttribute('disabled', 'true');
      signValidationBtn.setAttribute('disabled', 'true');
    }
  }
}
