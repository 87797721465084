// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  name: 'production',
  production: true,
  version: '2.0.2',
  rollbar_key: '',
  date_format: 'DD MM YY HH:mm:ss',
  api: {
client_id: '2za1ag86omm8sgssc0w88coks84wgkkc8gcw4888sw4ookowow',
client_secret: '677hgg7ads84ws8gg0owso0sk8ggscoskggws00kwww4ogogsc',
host: 'https://back.itnovem.3035.app',
    token_path:    '/oauth/v2/token'
  },
  sncf: {
callback_url: 'https://front.itnovem.3035.app/callback/',
connect_url: 'https://idp.sncf.fr/openam/oauth2/IDP/',
connect_id: 'PIALAB',
  },
tenant: 'sncf'
};
